@import "./shared/fonts";
@import "./shared/mixins";
@import "./shared/variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
